import { observer } from "mobx-react-lite";
import React from "react";
import AppState, { POPUPS, SCREENS } from "../../js/AppState";

import ScreenHome from "../ScreenHome/ScreenHome";
import ScreenLoading from "../ScreenLoading/ScreenLoading";

import PopupMentionsLegales from "../PopupMentionsLegales/PopupMentionsLegales";
import PopupVideo from "../PopupVideo/PopupVideo";
import ScreenGame from "../ScreenGame/ScreenGame";
import ScreenLogin from "../ScreenLogin/ScreenLogin";
import SettingsPopup from "../SettingsPopup/SettingsPopup";
import Notifs from "../shared/Notifs/Notifs";
import './App.css';
import PopupEndgame from "../PopupEndgame/PopupEndgame";

const App = () => {
  return (
    <div className="App">
      {AppState.screen === SCREENS.LOADING && <ScreenLoading />}
      {AppState.screen === SCREENS.HOME && <ScreenHome />}
      {AppState.screen === SCREENS.LOGIN && <ScreenLogin />}
      {AppState.loaderOverlay && <div className="Loader-overlay"><ScreenLoading /></div>}

      <ScreenGame />
      {/* {AppState.popup === POPUPS.TRAILER && <TrailerPopup />} */}
      {AppState.popupVisible(POPUPS.END_GAME) && <PopupEndgame />}
      {AppState.popupVisible(POPUPS.MENTION_LEGALES) && <PopupMentionsLegales />}
      {AppState.popupVisible(POPUPS.SETTINGS) && <SettingsPopup />}
      {AppState.popupVisible(POPUPS.VIDEO) && <PopupVideo />}

      <Notifs />
    </div>
  )
}

export default observer(App);
