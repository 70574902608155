import { observer } from 'mobx-react-lite';
import React from 'react'
import Texts from 'react-sdk/Texts'
import AppState from '../../../js/AppState';

import './SavegameNotif.css'

const SavegameNotif = () => {


  if(!AppState.saveExists) return null

  function handleDeleteSave(e) {
    e.preventDefault()
    AppState.deleteSave()
  }


  return (
    <div className="SavegameNotif">
      {Texts.get("homepage-savegame-exists")}
      <a href="#" onClick={handleDeleteSave}>{Texts.get("homepage-delete-savegame")}</a>
    </div>
  );
};

export default observer(SavegameNotif)
