import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import Texts from 'react-sdk/Texts';
import { getUrlVar } from 'react-sdk/utils';
import AppState, { POPUPS } from '../../js/AppState';
import Button from '../shared/Button/Button';

import './PopupVideo.css'

const PopupVideo = () => {

  const [showSkipButton, setshowSkipButton] = useState(false);

  const [playVisible, setPlayVisible] = useState(false);


  const video = useRef(null)
  let video_url = Texts.get(AppState.lp_api.args.id)
  const cantBeSkipped = getUrlVar("noskip", video_url) !== null

  useEffect(() => {
    if(showSkipButton) {
      let timer = setTimeout(() => setshowSkipButton(false), 1500)
      return () => clearTimeout(timer)
    }
  }, [showSkipButton]);


  useEffect(() => {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    let timer = setTimeout(() => {
      if(isSafari && video.current.paused) {
        setPlayVisible(true)
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, []);

  function handleClose() {
    AppState.removePopup(POPUPS.VIDEO)
    if(AppState.lp_api.args.onClose) AppState.lp_api.args.onClose()
  }

  function handleClick() {
    if(cantBeSkipped) return
    setshowSkipButton(true)
  }


  function handlePlay(e) {
    e.stopPropagation()
    video.current.play()
    setPlayVisible(false)
  }





  let audio = AppState.vertxApi.gameSettings.get().audio

  return (
    <div className="PopupVideo" onClick={handleClick}>

      {playVisible && <Button className='play-button' onClick={handlePlay}>{Texts.get("video-play")}</Button>}


      <video ref={video} muted={!audio} playsInline autoPlay src={video_url} onEnded={handleClose}></video>

      {showSkipButton && <Button className='skip-button'  onClick={handleClose}>{Texts.get("video-skip")}</Button>}

    </div>
  );
};

export default PopupVideo;
