import { observer } from 'mobx-react-lite';
import React from 'react';
import AppState, { SCREENS } from '../../js/AppState';
import './ScreenHome.css';

import Footer from '../shared/Footer/Footer';

import ScrollDownArrow from '../shared/ScrollDownArrow/ScrollDownArrow';
import TrailerSection from './TrailerSection/TrailerSection';
import FaqSection from './FaqSection/FaqSection';


import HeroSection from './HeroSection/HeroSection';
import Header from '../shared/Header/Header';

const ScreenHome = () => {


  return (
    <div className="ScreenHome" style={{backgroundPosition: `center ${AppState.bgOffset}px`}}>

      <HeroSection  />

      <TrailerSection />

      {AppState.stickyHeader &&
      <div className="sticky">
        <Header>

        </Header>
      </div>}

      {/* <FaqSection /> */}


      <ScrollDownArrow />
      <Footer />
    </div>
  );
};

export default  observer(ScreenHome)
