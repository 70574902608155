import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

import './FakeGame.css'
let HAS_SAVE = true
const FakeGame = () => {

  const [gameStarted, setGameStarted] = useState(false);
  const [withSavegame, setWithSavegame] = useState(null);
  useEffect(() => {
    console.log("USING FAKE GAME")

    window.LP_API.registerFunction("startGame", (withSave) => {
      console.log("startingGame withSave", withSave)
      setWithSavegame(withSave)
      setGameStarted(true)
    })

    window.LP_API.registerFunction("hasSave", (cb) => {
      console.log("hasSave", HAS_SAVE)
      cb(HAS_SAVE)
    })

    window.LP_API.registerFunction("deleteSave", (val) => {
      console.log("deleteSave")
      HAS_SAVE = false
    })


    window.LP_API.callFunction("signalGameReady")
  }, []);

  return (
    <div className="FakeGame">

      <h1>fake game</h1>

      <div>
        {/* <button onClick={() => window.LP_API.callFunction("goBackHome")}>Retourner à l'accueil</button> */}
        {/* <button onClick={() => window.LP_API.openSettings(onCloseSettings)} >Ouvrir les settings</button> */}
        {/* <button onClick={() => window.LP_API.openVideo('video-test', onVideoClose)} >Ouvrir la vidéo de test</button> */}
        <button onClick={() => window.LP_API.toggleFullscreen()} >Toggle fullscreen</button>
        <button onClick={() => window.LP_API.endGame({time: "02:50:33", victory: true})} >Fin du jeu</button>
        {!gameStarted && <h1>fonction "startGame" pas appelée ⚠ !</h1>}
        {gameStarted && <h1> fonction "startGame" appelée 👍 !</h1>}
        {withSavegame && <h1>Reprise du jeu avec la sauvegarde 💾</h1>}
        {!withSavegame && <h1>Commencement du jeu sans la sauvegarde 🆕</h1>}

      </div>
    </div>
  );
};

export default FakeGame;
