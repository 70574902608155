import { makeAutoObservable } from "mobx";
import DataLoading from 'react-sdk/DataLoading';
import ScaleManager from "react-sdk/ScaleManager";
import Texts from "react-sdk/Texts";
import { getUrlVar } from "react-sdk/utils";
import LP_api from "./LP_api";

export const SCREENS = {
  LOADING : "LOADING",
  HOME : "HOME",
  LOGIN : "LOGIN",
  GAME : "GAME",
  CHAT : "CHAT"
}


export const POPUPS = {
  MENTION_LEGALES: "MENTION_LEGALES",
  TRAILER: "TRAILER",
  SETTINGS: "SETTINGS",
  END_GAME: "END_GAME",
  VIDEO: "VIDEO",
  CREDITS: "CREDITS",
  CONTACT: "CONTACT"
}

class AppState {
  debugMode = false
  language = "fr"
  screen = SCREENS.LOADING

  // lp_api = null
  iframeKey = 0

  gameReady = false

  popups = new Set()
  loaderOverlay = false

  vertxApi = null

  skip_lp = false

  stickyHeader = false
  saveExists = false


  bgOffset = -270

  constructor() {

    this.debugMode = getUrlVar("debug")

    ScaleManager.init(1920, 1080)


    this.skip_lp = getUrlVar("skip_lp") !== null

    /**
     * @type LP_api
     */
    this.lp_api = new LP_api()

    window.LP_API = this.lp_api

    let vertxconfig = window.VertxConfig.createFromObjet(window.CONFIG.vertx)

    this.vertxApi = window.VertxApi.create(vertxconfig)

    DataLoading.getAllFiles(window.CONFIG.requiredFiles, window.CONFIG.root)
    .then(files => {
      Texts.init(files.texts, this.language)
    })
    .then(() => {
      return new Promise( resolve => {
        this.vertxApi.user.autoConnect((res, data) => {
          if(!res.status) {
            Notifications.error("Erreur de connexion au serveur (" + res.statusMessage + ")", 3000)
          }
          resolve()
        })
      })
    })
    .then(() => {
      if(this.skip_lp) {
        this.setScreen(SCREENS.GAME)
      }
      else {
        this.setScreen(SCREENS.HOME)
      }
    })


    makeAutoObservable(this, { vertxApi: false })
  }


  setScreen(id) {
    this.screen = id
  }

  setPopup(popup_id) {
    if(!this.popupVisible(popup_id)) {
      this.vertxApi.analytics.navigationEnter("popup." + popup_id)
      this.popups.add(popup_id)
    }
  }

  removePopup(popup_id) {
    if(this.popupVisible(popup_id)) {
      this.vertxApi.analytics.navigationLeave("popup." + this.popup)
      this.popups.delete(popup_id)
    }
  }

  popupVisible(popup_id) { return this.popups.has(popup_id) }
  clearPopups() { this.popups.clear() }


  playGame() {
    this.lp_api.registeredFunctions.startGame(this.saveExists)
    this.lp_api.activateFullscreen()

    this.setScreen(SCREENS.GAME)

  }

  deleteSave() {
    this.lp_api.registeredFunctions.deleteSave()

    this.lp_api.registeredFunctions.hasSave(save_exists => {
      this.saveExists = save_exists
    })
  }

  setLanguage(l) {
    this.language = l
    Texts.setLanguage(l)
  }

  refreshIframe() {
    this.iframeKey++
  }

}


export default new AppState()
