import React, { useRef, useState } from 'react';
import Texts from 'react-sdk/Texts';
import AppState, {SCREENS} from '../../../js/AppState';
import Button from '../../shared/Button/Button';
import Checkbox from '../../shared/Checkbox/Checkbox';


import button_identifier from '../../../images/button_identifier.png'
import button_google from '../../../images/button_google.png'
import button_register from '../../../images/button_register.png'
import Popup from '../../shared/Popup/Popup';


export default ({onForgotClick, onRegisterClick}) => {
  const [error, setError] = useState(null);
  const formRef = useRef(null)

  function handleSubmit (e) {
    e.preventDefault()
    setError(null)

    const data = new FormData(formRef.current)

    const email = data.get('email')
    const password = data.get('password')

    let remindMe = data.get('auto_reconnect') === null ? false : true

    AppState.loaderOverlay = true

    AppState.vertxApi.user.remindMe = remindMe


    console.log("LOGIN OF", email, "WITH REMEMBER=", remindMe)

    AppState.vertxApi.user.loginKeycloakCredentials(email, password, ({status, data, statusMessage}) => {
      console.log("status, data, statusMessage", status, data, statusMessage)
      AppState.loaderOverlay = false
      if(status) {
        console.log("LOGIN OK !!")
        AppState.setScreen(SCREENS.HOME)
      } else {
        console.log("LOGIN ERROR !!")
        setError(Texts.get(statusMessage))
      }
    })
  }

  function loginTier(provider) {

    AppState.vertxApi.user.remindMe = true



    AppState.loaderOverlay = true

    AppState.vertxApi.user.loginIDP(provider, ({status, data, statusMessage}) => {

      AppState.loaderOverlay = false

      if(status) {
        AppState.setScreen(SCREENS.HOME)
      } else {
        setError(Texts.get(statusMessage))
      }
    })
  }

  let errorstring = ""


  if(error) {
    if(error.error_description) errorstring = error.error_description
    else if (error.error) errorstring = error.error
    else if (typeof error === "string") errorstring = error
    else errorstring = JSON.stringify(error)
  }

  return (
    <Popup className='LoginForm' onClose={() => AppState.setScreen(SCREENS.HOME)}>
      <div className='Form'>
        <div className="ScreenLogin-columns">
          <div className="ScreenLogin-column">
            <form onSubmit={handleSubmit} ref={formRef}>
              <div>
                <label className="input-label"  htmlFor="email">{Texts.get("label-username")}</label>
                <input id="email" name="email" type="email" required />
              </div>

              <div>
                <label className="input-label" htmlFor="passwprd">{Texts.get("label-password")}</label>
                <input id="password" name="password" type="password" required/>
              </div>

              <div className="Form-remember">
                <Checkbox name="auto_reconnect" initialChecked={true} label={Texts.get("label-remember-me")} />
              </div>


              <div className="Form-buttons">
                <Button icon={button_identifier} type="submit" >{Texts.get("button-connection")}</Button>
                <Button icon={button_register} onClick={onRegisterClick}>{Texts.get("button-create-account")}</Button>
              </div>
              {error && <div className="error">{errorstring}</div>}
            </form>

            <div className="link" onClick={onForgotClick}>{Texts.get("link-password-forgotten")}</div>
          </div>

          <div className="ScreenLogin-column">
            <h3>{Texts.get("title-connect-with")}</h3>
            <div className="Form-buttons">
              <Button icon={button_google} onClick={() => loginTier("google")}>{Texts.get("button-google")}</Button>
            </div>
          </div>
        </div>

      </div>
    </Popup>)
}