import React, { useRef, useState } from 'react';
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '../../../js/AppState';
import Button from '../../shared/Button/Button';
import Popup from '../../shared/Popup/Popup';

export default ({onBack}) => {
  const formRef = useRef(null)
  const [error, setError] = useState(null);

  function handleSubmit (e) {
    e.preventDefault()
    setError(null)

    const data = new FormData(formRef.current)

    const password = data.get('password')
    const confirmPassword = data.get('confirm-password')


    if(password !== confirmPassword) {
      setError(Texts.get("error-password-dont-match"))
      return
    }


    const pseudo = data.get('pseudo')
    const mail = data.get('email')
    const prenom = data.get('prenom')
    const nom = data.get('nom')


    // https://github.com/Succubus-Interactive/GMS_2020/blob/master/ClientHaxe/src/gms/api/data/AccountCreationData.hx
    let new_user = {
      mail: mail,
      pseudo: pseudo,
      firstname: prenom,
      lastname: nom,
      password: password
    }

    AppState.loaderOverlay = true
    AppState.vertxApi.user.createUser(new_user, ({status, data, statusMessage}) => {
      if(status) {
        AppState.vertxApi.user.loginKeycloakCredentials(mail, password, () => {
          AppState.loaderOverlay = false
          AppState.setScreen(SCREENS.HOME)
        })
      } else {
        AppState.loaderOverlay = false
        setError(statusMessage)
      }
    })
  }

  return (
    <Popup className='RegisterForm' onClose={onBack}>
      <div className='Form'>
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="ScreenLogin-columns">
            <div className="ScreenLogin-column">
              <div>
                <label className="input-label"  htmlFor="email">{Texts.get("label-email")}</label>
                <input id="email" name="email" type="email" required />
              </div>
              <div>
                <label className="input-label"  htmlFor="pseudo">{Texts.get("label-pseudo")}</label>
                <input id="pseudo" name="pseudo" type="text" required />
              </div>
              <div>
                <label className="input-label"  htmlFor="prenom">{Texts.get("label-prenom")}</label>
                <input id="prenom" name="prenom" type="text" required />
              </div>

              <div>
                <label className="input-label"  htmlFor="nom">{Texts.get("label-nom")}</label>
                <input id="nom" name="nom" type="text" required />
              </div>
            </div>

            <div className="ScreenLogin-column">
              <div>
                <label className="input-label" htmlFor="passwprd">{Texts.get("label-password")}</label>
                <input id="password" name="password" type="password" required/>
              </div>
              <div>
                <label className="input-label" htmlFor="passwprd">{Texts.get("label-password-confirm")}</label>
                <input id="password" name="confirm-password" type="password" required/>
              </div>
            </div>
          </div>
          {error && <div className="error">{error}</div>}
          <Button type="submit">Submit</Button>
        </form>
      </div>
    </Popup>)
}
