import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Texts from 'react-sdk/Texts';
import AppState, { POPUPS } from '../../../js/AppState';
import './Header.css';

import button_play from '../../../images/button_play.png';
import Button from '../../shared/Button/Button';
import SavegameNotif from '../SavegameNotif/SavegameNotif';

const Header = () => {


  return (
    <div className="Header">
      <Button disabled={!AppState.gameReady} className="play-button" icon={button_play} onClick={() => AppState.playGame()}>
        {Texts.get("button-new-game")}
      </Button>
      <SavegameNotif />
    </div>
  );
};

export default  observer(Header)
