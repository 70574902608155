import React from 'react'
import { useRef } from 'react';
import { randomRange } from 'react-sdk/utils';

import './Button.css'


function makeClippath() {
  let values = [
    [randomRange(0, 8),
    randomRange(0, 8)],
    [randomRange(92, 100),
    randomRange(0, 8)],
    [randomRange(92, 100),
    randomRange(92, 100)],
    [randomRange(0, 8),
    randomRange(92, 100)]
  ].map(v => v.join('% ')).join('%, ') + "%"


  return `polygon(${values})`
}


const Button = React.forwardRef( ({icon, className, children, type="button", disabled, onClick}, ref) => {

  let clipPathRef = useRef(makeClippath())

  let classe = "Button"
  if(className) classe += " " + className
  if(icon) classe += " with-icon"

  return (
    <button ref={ref} className={classe} onClick={onClick} type={type} disabled={disabled}>

      <div className="Button-content" style={{clipPath: clipPathRef.current }}>
        {icon && <div className="Button-icon"><img src={icon} alt="button_icon" /></div>}
        {children && <div className="Button-text">{children}</div>}
      </div>

    </button>
  );
})

export default Button;
