import React from 'react'
import Texts from 'react-sdk/Texts';
import AppState, { POPUPS } from '../../js/AppState';
import Button from '../shared/Button/Button';
import drone from '../../images/drone.png';
import './PopupEndgame.css'
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Footer from '../shared/Footer/Footer';

const PopupEndgame = () => {
  function handleRestart() {
    AppState.refreshIframe()
    AppState.loaderOverlay = true
  }

  useEffect(() => {
    AppState.gameReady = false
  }, []);

  useEffect(() => {
    if(AppState.gameReady) {
      AppState.removePopup(POPUPS.END_GAME)
      AppState.loaderOverlay = false
      AppState.playGame()
    }
  }, [AppState.gameReady]);

  return (

    <div className="PopupEndgame">
      <div className="spacer" />
      <div className="PopupEndgame-content">
        <img src={drone} alt="drone"  className="drone"/>

        <h2>{Texts.get("end-game-title")}</h2>
        <div className='PopupEndgame-content-text' dangerouslySetInnerHTML={{__html: Texts.get("end-game-text")}} />
        <div className='PopupEndgame-content-buttons'>
            {Texts.get("show-end-game-button-tpdemain") === "1" &&
            <Button className="PopupEndgame-content-button">
              <a target="_blank" href={Texts.get("footer-link-tpavenir")}>
                  {Texts.get("end-game-button-tpdemain")}
              </a>
            </Button>}

          <Button className="PopupEndgame-content-button" onClick={handleRestart}>{Texts.get("end-game-button")}</Button>

        </div>

      </div>
      <div className="spacer" />
      <Footer />
    </div>
  );
};

export default observer(PopupEndgame)
