import React, { useEffect } from 'react'
import Button from '../Button/Button';

import button_close from "../../../images/button_close.png"
import './Popup.css'




const Popup = ({title, children, className="", onClose}) => {

  useEffect(() => {
    const handleEscape = e => { if(e.code === 'Escape')  onClose() }
    window.addEventListener('keydown', handleEscape)

    return () => { window.removeEventListener('keydown', handleEscape) }
  }, []);



  return (
    <div className="Popup-overlay" >

      <div className={"Popup " + className} onClick={e => e.stopPropagation()}>

        {title &&
        <div className="Popup-title">
          <h3>{title}</h3>
        </div>}

        {onClose && <Button className="Popup-close" icon={button_close} onClick={onClose} /> }

        <div className="Popup-clip-drop-shadow">
          <div className="Popup-clip">
            <div className="Popup-content" dangerouslySetInnerHTML={{__html: children}} />
          </div>
        </div>


      </div>

    </div>
  );
};

export default Popup;