import React from 'react'

import './ScrollDownArrow.css'

import arrows from '../../../images/scroll-arrows.png'
import { useEffect } from 'react';
import { useState } from 'react';

const ScrollDownArrow = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {

    const handleScroll = () => {
      let h = document.documentElement.scrollTop
      setVisible(h === 0)
    }


    window.addEventListener('scroll', handleScroll)

    handleScroll() // init

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  if(!visible) return null

  return (
    <div className="ScrollDownArrow">
      <img src={arrows} />
    </div>
  )

};

export default ScrollDownArrow;
