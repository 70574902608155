import React from 'react'
import { useState } from 'react';

import './Checkbox.css'

const Checkbox = ({onChange, initialChecked=false, name, label}) => {
  const [checked, setChecked] = useState(initialChecked);

  function handleChange(e) {
    let _checked = e.target.checked

    setChecked(_checked)
    if(onChange) onChange(_checked)

  }
  return (
    <label className="Checkbox">
      <input type="checkbox" onChange={handleChange} checked={checked} value={checked} name={name} />
      <span></span>
      {label}
    </label>
  );
};

export default Checkbox;
