import { makeAutoObservable, toJS } from "mobx"

class ScaleManager {

  w = null
  h = null

  scale = 1
  scaleOffSet = 0
  margin = 0
  marginPos = null
  _style = {} // ne pas utiliser direct, il faut le toJS()

  get style() { return toJS(this._style) }

  constructor() {
    makeAutoObservable(this)

    this.recalculate = this.recalculate.bind(this)
  }

  init(w, h) {
    this.w = w
    this.h = h

    this.recalculate()
    this.start()
  }

  pause() {
    window.removeEventListener("resize", this.recalculate)
  }

  start() {
    window.addEventListener("resize", this.recalculate)
  }




  recalculate(){
    let w = this.w
    let h = this.h

    let ratio = w/h
    let windowRatio = window.innerWidth / window.innerHeight

    let style = {
      transformOrigin: "top left",
      width: w,
      height: h
    }
    let scale = 1
    let margin = 0

    if(windowRatio > ratio) {
      let w = window.innerHeight * ratio
      scale = window.innerHeight / h
      margin = (window.innerWidth - w) / 2
      this.marginPos = "left"
      style.marginLeft = margin

    } else {
      let h = window.innerWidth / ratio
      scale = window.innerWidth / w
      margin = (window.innerHeight - h) / 2
      this.marginPos = "top"
      style.marginTop = margin
    }

    scale += (this.scaleOffSet * scale)

    style.transform = "scale("+scale+")"


    this._style = style
    this.scale = scale
    this.margin = margin

  }
}


export default new ScaleManager()