import React from 'react'

import './Footer.css'

import succubus_logo from "../../../images/succubus-footer-logo.png"
import AppState, { POPUPS } from '../../../js/AppState';
import Texts from 'react-sdk/Texts';




import logo_fntp from '../../../images/logos/TP_Q.png'
import logo_ministere from '../../../images/logos/EDEC.png'
import logo_cca_btp from '../../../images/logos/logoQuadri_CCCA.png'
import logo_tp_avenir from '../../../images/logos/logo_tp_d_avenir_-_tp.demain.png'






const Footer = () => {


  function openMentionsPopup(e) {
    e.preventDefault()
    AppState.setPopup(POPUPS.MENTION_LEGALES)
  }


  return (
    <div className="Footer">
      <div className="menu-bottom">
        <a href="#" onClick={openMentionsPopup}>{Texts.get("mentions-legales-button")}</a>
      </div>

      <div className="logos">
        <a href={Texts.get("footer-link-fntp")} target="_blank" className="logo"><img src={logo_fntp} alt="logo FNTP" /></a>
        <a href={Texts.get("footer-link-ministere")} target="_blank" className="logo"><img src={logo_ministere} alt="logo Ministère du travail, de l'emploi et de l'insertion" /></a>
        <a href={Texts.get("footer-link-ccca-btp")} target="_blank" className="logo"><img src={logo_cca_btp} alt="logo CCCA-BTP" /></a>
        <a href={Texts.get("footer-link-tpavenir")} target="_blank" className="logo"><img src={logo_tp_avenir} alt="logo TP d'avenir, tp.demain" /></a>
      </div>


      <div className="baseline">
        <img src={succubus_logo} alt="logo-succcubus" />
        <div>Seriously made with fun <br />by <a href="https://www.succubus.fr" target='_blank'>succubus interactive</a></div>
      </div>
    </div>
  );
};

export default Footer;
