import React from 'react'
import Texts from 'react-sdk/Texts';

import YouTube from 'react-youtube';

import './TrailerSection.css'

const youtubeConfig = {

  autoplay: 0,
  controls: 1,
  cc_load_policy: 0,
  fs: 1,
  iv_load_policy: 3,
  modestbranding: 1,
  rel: 0,
  showinfo: 0,
}

const TrailerSection = () => {

  function makeFullscreen(e) {
    let ifra = e.target.getIframe()
    ifra.requestFullscreen()
  }
  return (
    <section className='TrailerSection'>
      <div className="TrailerSection-clip">
        <YouTube
        onPlay={makeFullscreen}
        className="youtube-player"
        iframeClassName="youtube-player-iframe"
        videoId={Texts.get("trailer-youtubeid")} opts={{ height: '100%', width: '640', playerVars: youtubeConfig}} />
      </div>

    <div className="TrailerSection-description-ctnr">
      <div className="TrailerSection-description">
        <h2>{Texts.get("trailer-description-title")}</h2>
        <p>{Texts.get("trailer-description")}</p>
        </div>
    </div>
  </section>

  );
};

export default TrailerSection;
