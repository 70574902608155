import React, { useState, useRef } from 'react';
import Texts from 'react-sdk/Texts';
import AppState from '../../../js/AppState';
import Button from '../../shared/Button/Button';
import Popup from '../../shared/Popup/Popup';

export default ({onDone}) => {

  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);
  const [errorStep2, setErrorStep2] = useState("");



  const mailRef = useRef(null)

  function handleSubmit(e) {
    e.preventDefault()
    setError(null)

    const data = new FormData(e.target)

    const email = data.get('email')

    mailRef.current = email

    AppState.vertxApi.user.sendRecoveryMail(email, ({status, data, statusMessage}) => {
      if(status) {
        setStep(1)
      } else {
        setError(Texts.get(statusMessage))
      }
    })
  }

  function handleSendNewPassword(e) {
    e.preventDefault()
    const data = new FormData(e.target)
    const email = data.get('email')

    const code = data.get('code')
    const password = data.get('password')
    const password2 = data.get('confirm-password')

    if(password !== password2) {
      setErrorStep2(Texts.get("error-password-dont-match"))
      return
    }

    AppState.vertxApi.user.passwordReset(email, password, code, ({status, data, statusMessage}) => {
      if(status) {
        onDone()
      } else {
        setErrorStep2(statusMessage)
      }
    })


  }

  return (
    <Popup onClose={onDone}>
      <div className='Form'>
        <h2>Reset password</h2>

        {step === 0  &&
        <form onSubmit={handleSubmit}>
          <div>
            <label className="input-label"  htmlFor="email">{Texts.get("label-email")}</label>
            <input id="email" name="email" type="email" required />
          </div>

          {error && <div className="error">{error}</div>}

          <div className="Form-buttons">
            <Button type='submit'>{Texts.get("forgotten-pwd-send-code")}</Button>
            <Button onClick={() => setStep(1)}>{Texts.get("forgotten-pwd-i-have-code")}</Button>
          </div>

        </form>}

        {step === 1  &&
        <form onSubmit={handleSendNewPassword}>

          <div>
            <label className="input-label" htmlFor="code">{Texts.get("forgotten-pwd-label-code")}</label>
            <input id="code" name="code" type="text" required/>
          </div>
          {!mailRef.current &&
          <div>
            <label className="input-label"  htmlFor="email">{Texts.get("label-email")}</label>
            <input id="email" name="email" type="email" required />
          </div>}
          <div>
            <label className="input-label" htmlFor="passwprd">{Texts.get("label-password")}</label>
            <input id="password" name="password" type="password" required/>
          </div>
          <div>
            <label className="input-label" htmlFor="confirm-password">{Texts.get("label-password-confirm")}</label>
            <input id="confirm-password" name="confirm-password" type="password" required/>
          </div>



          {mailRef.current && <input name="email" type="hidden" value={mailRef.current}/>}




          {errorStep2 && <div className="error">{errorStep2}</div>}


          <Button type="submit">{Texts.get("forgotten-pwd-submit")}</Button>
        </form>}


      </div>
    </Popup>)
}