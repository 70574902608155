import React from 'react'
import Texts from 'react-sdk/Texts';
import AppState, { POPUPS } from '../../js/AppState'
import Popup from '../shared/Popup/Popup'

import './PopupMentionsLegales.css'

const PopupMentionsLegales = () => {

  function handleClose() {
    AppState.removePopup(POPUPS.MENTION_LEGALES)
  }

  return (
    <Popup title={Texts.get("mentions-legales-popup-title")} onClose={handleClose} className="PopupMentionsLegales">
      {Texts.get("mentions-legales-popup-content")}
    </Popup>
  );
};

export default PopupMentionsLegales;
