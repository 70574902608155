import React, { useEffect, useRef } from 'react'
import AppState, { SCREENS } from '../../js/AppState';
import { observer } from 'mobx-react-lite';
import FakeGame from './FakeGame/FakeGame';

import './ScreenGame.css';

const ScreenGame = () => {

  const isvisible = AppState.screen === SCREENS.GAME

  useEffect(() => {
    let params = {}
    if(window.lime){
      console.log("LOADING GAME !!!")
      window.lime.embed ("after-storm", "game-content", 1920, 1080, { parameters: params });
    }
    else {
      console.log("lime not available")
    }
  }, []);

  return (
    <div className={"ScreenGame" + (isvisible ? " visible" : "")}>

      <div id="game-content">
        {!window.lime && <FakeGame />}
      </div>

    </div>
  );
};

export default observer(ScreenGame)
