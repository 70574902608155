import React from 'react'
import Texts from 'react-sdk/Texts';
import Button from '../../shared/Button/Button'

import logo from "../../../images/logo.png"
// import button_manette from '../../../images/button_manette.png';
import button_play from '../../../images/button_play.png';
import drone from '../../../images/drone.png';

import './HeroSection.css'
import { useEffect } from 'react';
import { useState } from 'react';
import AppState from '../../../js/AppState';
import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import SavegameNotif from '../../shared/SavegameNotif/SavegameNotif';

const HeroSection = () => {

  const btnRef = useRef(null)
  const contentRef = useRef(null)

  // NOTE teste pour savoir si on a une sauvegarde
  useEffect(() => {
    if(!AppState.gameReady) return
    AppState.lp_api.registeredFunctions.hasSave(save_exists => {
      AppState.saveExists = save_exists
    })
  }, [AppState.gameReady]);



  // NOTE affiche ou cache le sticky header basé sur la visibilité du bouton
  useEffect(() => {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    }

    let observer = new IntersectionObserver((entries, observer) => {
      AppState.stickyHeader = !entries[0].isIntersecting
    }, options);
    observer.observe(btnRef.current)

  }, []);

  /*
  NOTE : ce useEffect permet de garder laligne d'horizon du background sur la limite supérieure du div.HeroSection-content
  ainsi si on resize la page en hauteur, le calcul est fait afin de déplacer verticalement le BG pour le garder à la meme place visuellement
  */
  useEffect(() => {
    const getHeight = () => {
      let h = contentRef.current.offsetTop
      AppState.bgOffset = h - 955
    }
    getHeight()
    window.addEventListener('resize', getHeight)

    return () => window.removeEventListener('resize', getHeight)


  }, []);



  return (
    <section className="HeroSection">

      <div className="HeroSection-title">
        <h2>{Texts.get("homepage-title-h2")}</h2>
        <h1>
          <img src={logo} alt={Texts.get("logo-alt-text")} />
        </h1>
      </div>


      <div className="HeroSection-content" ref={contentRef}>
        <Button ref={btnRef} disabled={!AppState.gameReady} className="play-button" icon={button_play} onClick={() => AppState.playGame()}>
          {Texts.get("button-new-game")}
        </Button>

        <SavegameNotif />
        <div className="HeroSection-description">
          {Texts.get("homepage-description")}
        </div>


        <img src={drone} alt="drone"  className="drone"/>
      </div>
    </section>
  );
};

export default observer(HeroSection)
