import AppState, { POPUPS, SCREENS } from "./AppState"



export default class LP_api {
  onFullscreen = false
  args = null

  registeredFunctions = {}

  getVars() {
    if(this.args.vars) return this.args.vars
    return {}
  }
  openSettings(vars, onClose, onChange) {
    this.args = {vars, onClose, onChange}
    AppState.setPopup(POPUPS.SETTINGS)
  }

  endGame(vars){
    this.args = {vars}

    AppState.setPopup(POPUPS.END_GAME)
  }
  getVertxApi(){
    return AppState.vertxApi
  }

  toggleFullscreen() {
    if(this.onFullscreen){
      this.deactivateFullscreen()
    }
    else {
      this.activateFullscreen()
    }
  }

  activateFullscreen() {
    var isFullscreenAvailable = document.fullscreenEnabled;
    if(!isFullscreenAvailable) {
      console.log("fullscreen not authorized")
      return
    }
    window.document.documentElement.requestFullscreen()
    this.onFullscreen = true
  }

  deactivateFullscreen() {
    var isFullscreenAvailable = document.fullscreenEnabled;
    if(!isFullscreenAvailable) {
      console.log("fullscreen not authorized")
      return
    }
    window.document.exitFullscreen()
    this.onFullscreen = false
  }

  openVideo(id, onClose) {
    this.args = {id, onClose}
    AppState.setPopup(POPUPS.VIDEO)
    this.activateFullscreen()
  }

  registerVirtualKeyboardEvent(callback) {
    if ('virtualKeyboard' in navigator) navigator.virtualKeyboard.addEventListener('geometrychange', callback)
  }


  callFunction(name, param) {

    switch(name) {
      case "signalGameReady": AppState.gameReady = true
        break;
      // case "muteJitsi": Jitsi.mute(); break;
      // case "unmuteJitsi": Jitsi.unmute(); break;
      // case "toggleMuteJitsi": Jitsi.toggleMute(); break;
      // case "updateGlobalTimer": AppState.globalTimer = param; break;
      // case "showGlobalTimer": AppState.globalTimervisible = param; break;
    }
  }

  registerFunction(name, func) {
    this.registeredFunctions[name] = func
  }

}