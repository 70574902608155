import React, { useState } from 'react'
import Texts from 'react-sdk/Texts';
import AppState, { POPUPS } from '../../js/AppState';
import Checkbox from '../shared/Checkbox/Checkbox';
import Popup from '../shared/Popup/Popup';

import './SettingsPopup.css'

const SettingsPopup = () => {

  function handleClose() {
    AppState.removePopup(POPUPS.SETTINGS)
    if(AppState.lp_api.args?.onClose) AppState.lp_api.args.onClose()
  }

  let settings = AppState.vertxApi.gameSettings.get()

  return (

    <Popup
      className="SettingsPopup"
      title={Texts.get("settings-popup-title")}
      onClose={handleClose}>

      {settings.audio !== undefined &&
      <div className="SettingsPopup-option">
        <div className="SettingsPopup-option-text">
          {Texts.get("settings-popup-volume")}
        </div>
        <Checkbox initialChecked={settings.audio} onChange={checked => AppState.vertxApi.gameSettings.set({audio: checked })} />
      </div>}

    </Popup>
  );
};

export default SettingsPopup;
